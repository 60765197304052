.no-suggestions {
  color: $no-suggestion;
  padding: 0.5rem 0rem;
  font-size: 14px;
}

.suggestions {
  border: 1px solid $no-suggestion;
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  max-height: 95px;
  overflow-y: auto;
  padding-left: 0;
  width: 80%;
}

.suggestions li {
  padding: 0.2rem;
}

.suggestion-active,
.suggestions li:hover {
  background-color: $suggestion-bg;
  color: $body-bg;
  cursor: pointer;
  font-weight: 700;
}

.suggestions li:not(:last-of-type) {
  border-bottom: 1px solid $suggestion-border;
}
